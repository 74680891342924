/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
:root {
  font-size:62.5%;
}

.swal2-icon {
  width:55px !important;
  height: 55px !important;
  border-width: 2px !important;
  font-size:12px !important;
}

body {
  --main-page-dark-blue: #2d4484;
  --main-page-light-blue: #e3efff;
  --main-white: #fefefe;
  --inspecciones-page-light-blue: #3167AF;
  --inspecciones-page-dark-blue: #26529F;
  --inspecciones-page-faint-blue: #7D9EB5;
  --inspecciones-page-black: #020202;
  --inspecciones-page-light-gray: #F0F5F8;
  --inspecciones-page-gray: #3D3D3D;
  --inspecciones-page-gray-text: #737373;
  --inspecciones-page-yellow: #FD962A;
  --inspecciones-page-transparent-pink: #E34FA489;
  --inspecciones-page-pink: #C332A1;
  --inspecciones-page-light-yellow: #FAF5BA;
  --inspecciones-page-dark-yellow: #917C23;
  --inspecciones-page-brown: #835218;
  --inspecciones-page-light-green: #C2EBBB;
  --inspecciones-page-dark-green: #288300;
  --inspecciones-page-main-red: #a6140c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::ng-deep .display-text {
  overflow-x: hidden !important;
}

.nsdicon-angle-down {
  color: var(--inspecciones-page-dark-blue);
}

.textarea {
  display: block;
  padding:10px;
  width: 100%;
  resize: vertical;
  min-height: 115px;
  height: auto;
  border: 1px solid #ccc;
  font-size: 1.8rem;
  background-color: var(--inspecciones-page-light-gray);
}


.aggregate-button {
  color: var(--inspecciones-page-yellow);
  background-color: transparent;
  border:none;
  padding:0;
  font-size: 1.8rem;
}

.submit-button {
  background-color: var(--inspecciones-page-dark-blue);
  padding:13px 30px;
  width: 150px;
  height:44px;
  border: none;
  border-radius: 7px;
  color: var(--main-white);
  font-size: 1.8rem;
  font-family: 'Nexa Light', sans-serif;
  line-height: 1.2rem;
}

.delete-button {
  background-color: var(--main-white);
  padding:13px 30px;
  width: 150px;
  height:44px;
  border: none;
  border-radius: 7px;
  border-color: var(--inspecciones-page-main-red);
  color: var(--inspecciones-page-main-red);
  font-size: 1.8rem;
  font-family: 'Nexa Light', sans-serif;
  line-height: 1.2rem;
}

.mat-card-content,
.mat-card-header .mat-card-title,
.mat-card-subtitle {
  font-size: 16px;
  color: #2d4484;
}

.mat-progress-bar-fill::after {
  background-color: #3167af;
}

.mat-progress-bar-buffer {
  background-color: #eeeeee;
}

.mat-card-header-text {
  width: 100%;
}

.aside-prev-mec {
  width: 30%;
  padding-right: 20px;
}

.inspecciones-principal {
  width: 100%;
  min-height: 100vh;
  background-color: var(--main-white);
  padding: 92px 5% 0;
}

.d-flex-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.d-flex-between-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.d-flex-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.d-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.d-flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

td {
  font-size: 16px !important;
}

.red-text {
  color: var(--inspecciones-page-main-red);
}

.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-0 {
  margin-right: 0;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-50 {
  margin-right: 50px;
}

.top-line {
  padding-top:15px;
  border-top: 1px solid #C0C7D1;
}

.pt-30 {
  padding-top: 30px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.tooltip-container {
  position: relative;
  display: inline;
}

.tooltip-container:hover .tooltip-text {
  display: block;
  z-index: 1;
}

.tooltip-text {
  position: absolute;
  left:0;
  top:40px;
  padding:10px;
  border-radius: 5px 10px 10px 10px;
  line-height: 1.6rem;
  font-size:1.4rem;
  min-width: 20vw;
  background-color: var(--inspecciones-page-black);
  color: var(--main-white);
  display: none;
}

.tooltip-text::before {
  display: block;
  position:absolute;
  content:"";
  width:15px;
  height:15px;
  margin: 0;
  z-index:-1;
  top:-8px;
  background-color: var(--inspecciones-page-black);
  transform: rotate(45deg);
}

.inspections-label {
  font-size:1.8rem;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 2.2rem;
  color: var(--inspecciones-page-black);
  font-family: Helvetica, sans-serif;
}

.inspections-label-gray {
  font-size:1.8rem;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 2.2rem;
  color: #9D9D9D;
  font-family: Helvetica, sans-serif;
}

.inspections-input {
  background-color: var(--inspecciones-page-light-gray);
  border-radius: 7px;
  border: none;
  padding: 13px 30px;
  font-size: 1.6rem;
  font-family: Helvetica, sans-serif;
  max-height: 45px;
}

.text-disabled {
  color: #AAAAAA;
}

.inspections-select {
  background-color: var(--inspecciones-page-light-gray);
  border-radius: 7px;
  border: none;
  padding: 0px;
  font-size: 1.6rem;
  font-family: Helvetica, sans-serif;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inspections-subtitle {
  font-size: 1.8rem;
  color: var(--inspecciones-page-gray)
}

.inspections-select button.ngx-dropdown-button {
  background-color: transparent !important;
  border:none !important;
  padding: 10px 30px !important;
  height:45px;
}

.calendar-input::-webkit-calendar-picker-indicator {
  background-image: url('/assets/img/inspecciones/calendar.png');
  width:23px;
  height: 25px;
}

.time-input::-webkit-calendar-picker-indicator {
  background-image: url('/assets/img/inspecciones/reloj.png');
  width: 23px;
  height: 23px;
}

.form-group {
  margin-bottom: 15px;
}

.full-width-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.half-width-group {
  width: 48%;
  display: flex;
  flex-direction: column;
}

.principal-title {
  font-size:40px;
  color: var(--inspecciones-page-light-blue);
  font-weight: bold;
}

.secondary-title {
  color: var(--inspecciones-page-light-blue);
  font-size:25px;
  font-weight: bold;
}

.module-principal {
  background-color: var(--main-white);
  min-height: 100vh;
  width: 100%;
  font-family: Helvetica, sans-serif;
  padding: 120px 100px;
}

.form-container {
  border: 1px solid #C0C7D1;
  border-radius: 7px;
  padding: 30px 40px;
}

.form-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
}

.form-title {
  font-size:25px;
  margin: 0px 0px 9px 0px;
  font-weight: bold;
}

.download-button {
  background-color: var(--inspecciones-page-yellow);
  border: none;
  height:44px;
  min-width:125px;
  padding:4px 20px;
  border-radius: 7px;
}

.bold {
  font-weight: bold;
}

.download-image {
  height: 32px;
}

.phase-form {
  width: 70%;
  min-width: 300px;
}

.go-back-button {
  background-color: #EFF4F7;
  border-radius: 7px;
  border: none;
  padding: 10px;
  margin-right: 30px;
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  line-height: 20px;

  display: flex;
  align-items: center;
}

.go-back-image {
  width:20px;
  height:20px;
  margin-right: 10px;
}

.file-container {
  display: flex;
  background-color: var(--inspecciones-page-light-gray);
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: none;
  text-align: center;
  width: 200px;
  height: 60px;
  padding: 20px;
  cursor: pointer;
}

.file-container-siging {
  display: flex;
  background-color: var(--inspecciones-page-light-gray);
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: none;
  text-align: center;
  max-width: 249px;
  max-height: 45px;
  padding: 13px 3px;
  cursor: pointer;
  margin-bottom: 0px;
}

.upload-image-pva-siging{
  width: 21px;
  height: 21px;
  margin-left: 25%;
}

.input-siging{
  max-width: 250px;
  height: 45px;
}

.file-container-45 {
  height: 45px !important;
  width: 100%;
  padding:13px 30px;
}

.input-width{
  max-width: 80%;
}

.section-text {
  font-size: 1.8rem;
  font-family: Helvetica, sans-serif;
}

.file-container p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.upload-image {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.upload-image-pva {
  width: 21px;
  height: 21px;
  margin-left: 10px;
}

.upload-image-pva-left {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

.file-info {
  position: relative;
  top: 0;
}

.file-input {
  display: none !important;
}

.checkbox-container {
  height: 30px;
  margin-right: 10px;
  min-width: 100px;
  width:fit-content;
}

.checkbox {
  min-width: 20px !important;
  min-height: 20px !important;
  margin-right: 10px !important;
  margin-top: 0 !important;
  display: inline;
}

.edit-item {
  border:none;
  font-size:1.8rem;
}

.button-icon {
  width:20px;
  height:20px;
}

.delete-item {
  font-size: 1.8rem;
  color: var(--inspecciones-page-main-red);
  border: 2px solid var(--inspecciones-page-main-red);
}

.font16 {
  font-size: 1.6rem;
}

.font18 {
  font-size: 1.8rem;
}

.p-siging {
  text-align: left;
  font: normal normal 300 17px/19px;
  font-family: Helvetica, sans-serif;
  margin-top: 9px;
}

.siging-but{
  text-align: left;
}

.p-relative-registros {
  width:fit-content;
  display: inline-block;
  height: fit-content;
  position: relative;
}

.uploaded-image {
  width: 100%;
  height: auto;
  max-width: 100px;
  max-height: 100px;
}

.delete-uploaded-image {
  width: 25px;
  height: 25px;
  position: absolute;
  top: -10px;
  right: -22px;
  cursor: pointer;
}

.cancel-button {
  background-color: transparent;
  border: 2px solid var(--inspecciones-page-yellow);
  font-size:1.8rem;
  text-align: center;
  color: var(--inspecciones-page-yellow);
  min-width:125px;
  width:fit-content;
  height:44px;
  border-radius: 7px;
}

.cancel-gray {
  background-color: transparent;
  border: 2px solid var(--inspecciones-page-gray-text);
  font-size:1.8rem;
  text-align: center;
  color: var(--inspecciones-page-gray-text);
  min-width:125px;
  width:fit-content;
  height:44px;
  border-radius: 7px;
}

.cancel-small-button {
  background-color: transparent;
  border: none;
  font-size: 1.8rem;
  text-align: center;
  color: var(--inspecciones-page-gray-text);
}

.selectable-button {
  color: var(--main-page-dark-blue);
  margin-bottom: 24px;
  font-size: 2rem;
  text-align: left;
  line-height: 2.4rem;
  letter-spacing: 0px;
  background-color: transparent;
  border:none;
  padding:0;
  display: block;
}

.selectable-button:hover {
  text-decoration: underline;
}

.selectable-button .right-arrow {
  margin-left: 24px;
  width: 16px;
  height:16px;
}

.list-detail {
  font-size: 1.6rem;
  text-align: left;
  padding: 14px 30px;
  background-color: var(--inspecciones-page-light-gray);
  border-radius: 7px;
  position:relative;
  width:70% !important;
  min-height:45px;
  line-height: 1.6rem;
  margin-bottom: 10px;
}


.fit-height {
  height: fit-content;
}

.min-100per {
  min-width: 100%;
}

.max-70per {
  max-width: 70%;
}

.relative {
  position: relative;
}

.list-buttons {
  position:absolute;
  right: -38px;
  top: 0px;
  align-items: center;
  display: flex;
  justify-content: center;
  height:100%;
}

.right-double {
  right: -70px;
  height:100%;
}

.selectable-button {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.selectable-button .edit-icon {
  width: 20px;
  height:20px;
  margin-left: 24px;
}

.selectable-button .accept-icon {
  width: 20px;
  height:20px;
  margin-left:24px;
}

.selectable-button .delete-icon {
  width: 20px;
  height: 20px;
  margin-left:10px;
}

.bottom-line {
  padding-bottom:15px;
  margin-bottom:20px;
  border-bottom: 1px solid #C0C7D1;
}

.delete-inline-button {
  width: fit-content;
  height: auto;
  margin:0;
  color: #910808;
  border: none;
  background-color: transparent;
  outline: none;
}

.delete-inline-button:hover {
  text-decoration: underline;
}

.alert-field {
  color: #910808;
  margin-top:5px;
}

.width-100 {
  width: 100%;
}
